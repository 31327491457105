<template>
  <div>
    <!-- MOBILE -->
    <div class="mb-5 pb-10" style="height:100%">
      <div v-if="reload" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          width="100%"
          height="270px"
          class="round mb-3"
          elevation="0"
          outlined
          v-for="n in 3"
          :key="n+'A'"
        >
        <v-skeleton-loader
          type="card"
        ></v-skeleton-loader>
        </v-card>
      </div>
      <div v-else>
        <v-expansion-panels v-model="openExpand"  class="round">
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header>
              <b>Untuk Brand</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>1. Klien bertanggung jawab penuh atas penggunaan nama brand dan materi yang digunakan.</p>
              <p>2. Klien mengakui bahwa setiap konten yang di unggah oleh Influencer sudah disetujui oleh Brand dan merupakan tanggung jawab Brand.</p>
              <p>3. Brand melepaskan VDC dari semua tanggung jawab atas ketidaksesuaian konten dan kelalaian dari pihak Influencers.</p>
              <p>4. VDC hanyalah perantara dan bukan merupakan wakil dari, kuasa hukum, penerima penunjukan, agen, perwakilan dari Influencer.</p>
              <p>5. VDC tidak bertanggung jawab atas segala perbuatan, perkataan, tindakan, kelalaian, kesengajaan dari Influencer.</p>
              <p>6. Pembayaran yang sudah dilakukan di VDC tidak dapat ditarik kembali.</p>
              <p>7. Materi iklan tidak mengandung unsur kekerasan, pornografi, SARA, ataupun materi yang melanggar perundang-undangan yang berlaku di Indonesia dan/atau norma kesusilaan di masyarakat.</p>
              <p>8. Dilarang untuk membuat konten yang bertujuan dan/atau mengakibatkan timbulnya kesan, penilaian, citra publik buruk pada produk pesaing, atau terhadap pihak manapun juga.</p>
              <p>9. Untuk produk kecantikan, obat-obatan dan sejenisnya harus memiliki izin edar dari Badan Pengawas Obat dan Makanan (BPOM).</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="fourth">
            <v-expansion-panel-header>
              <b>Untuk Influencers</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="active--class">
              <p>1. Influencer wajib mencantumkan <b>@vdc.management</b> di bio instagram atau tiktok sebagai bentuk identifikasi dan validasi yang diberikan kepada brand.</p>
              <p>2. Segala perubahan informasi mengenai detail akun atau rate card wajib menghubungi VDC segera. VDC tidak bertanggung jawab apabila terdapat ketidaksesuaian data yang belum ter-update.</p>
              <p>3. Influencer yang sudah bergabung dalam suatu campaign atau job dilarang <b>membatalkan</b> secara sepihak tanpa konfirmasi ke Tim VDC.</p>
              <p>4. Influencer wajib menyelesaikan tugasnya sesuai dengan <b>batas waktu</b> yang telah di informasikan. Segala keterlambatan oleh pihak Influencer menjadi tanggung jawab penuh Influencer.</p>
              <p>5. VDC berhak melakukan pembatalan apabila terdapat <b>ketidaksesuaian</b> influencer dalam menjalani tugasnya.</p>
              <p>6. VDC berhak memberikan <b>sanksi</b> apabila terdapat hal yang merugikan atau <b>melanggar hukum</b>.</p>
              <p>7. Segala <b>kehilangan atau kerusakan</b> barang yang sudah diterima oleh influencer, menjadi tanggung jawab penuh influencer.</p>
              <p>8. VDC akan melakukan pembayaran <b>maksimal 7 hari kerja</b> setelah tanggal tayang.</p>
              <p>9. Untuk memastikan pembayaran yang tepat, Influencer bertanggung jawab untuk menyediakan <b>informasi pembayaran</b> yang benar. Segala kesalahan dalam informasi pembayaran yang sudah diberikan bukan tanggung jawab dari VDC.</p>
              <p>10. VDC berhak <b>menolak</b> untuk membayar Influencer apabila VDC mengetahui bahwa terdapat hal yang melanggar atau ketidaksesuaian.</p>
              <p>11. Influencer dilarang untuk membuat konten yang bertujuan dan/atau mengakibatkan timbulnya kesan, penilaian, <b>citra publik buruk</b> pada produk pesaing, atau terhadap pihak manapun juga.</p>
              <p>12. Influencer dilarang membuat konten yang mengandung unsur <b>kekerasan, pornografi, SARA</b>, ataupun materi yang <b>melanggar perundang-undangan</b> yang berlaku di Indonesia dan/atau norma kesusilaan di masyarakat.</p>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <p class="text-center mt-5">Jangan tunggu besok, langsung chat admin VDC sekarang ya!</p>
        <div class="d-flex justify-center">
          <v-btn @click="goToWa()" color="primary" rounded class="text-capitalize"><i class="text-18 fab fa-whatsapp mr-2"></i>Chat Admin</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      reload:true,
      isMobileWidth:0,
      openExpand:''
    }
  },
  mounted(){
    if(this.isMobileWidth === 0){
      if (localStorage.getItem('reloaded')) {
          localStorage.removeItem('reloaded');
          this.reload = false
      } else {
          localStorage.setItem('reloaded', '1');
          location.reload();
      }
      window.scrollTo(0,0);
    }

    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
    if(this.isMobileWidth === 0){
      this.openExpand = 0
    } else {
      this.openExpand = ''
    }
  },
  methods:{
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    goToWa(){
      window.open('https://wa.me/6285691701929?text=Halo Admin, Boleh dijelaskan lebih detail terkait service yang ada di VDC ini? \n Terima kasih', '_blank');
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  }
}
</script>
